// Copyright (C) 2023-2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState, useEffect } from '@modules/react';
import { useSelector } from '@modules/react-redux';
import { MenuProps } from '@modules/antd/lib/menu';

import { CombinedState } from '@root/reducers';
import { Organization } from '@root/cvat-core-wrapper';

export interface MenuButtonProps {
    link: string;
    text: string;
    icon: JSX.Element;
    className: string;
    key: string;
}

export const MenuButtonHOC = (
    hasLimits: () => null | Promise<boolean>,
    getProps: (isPremium: boolean, currentOrganization: Organization | null) => MenuButtonProps,
) => (
    { targetProps }: { targetProps: { currentOrganization: Organization | null } },
): NonNullable<MenuProps['items']>[0] => {
    const user = useSelector((state: CombinedState) => state.auth.user);
    const organization = useSelector((state: CombinedState) => state.organizations.current);
    const [isPremium, setIsPremium] = useState(false);

    useEffect(() => {
        const val = hasLimits();
        if (val !== null) {
            val.then((_hasLimits) => {
                setIsPremium(_hasLimits);
            });
        }
    }, [user, organization]);

    const { currentOrganization } = targetProps;
    const {
        link,
        text,
        icon,
        className,
        key,
    } = getProps(isPremium, currentOrganization);

    return {
        className,
        icon,
        key,
        onClick: () => window.open(link, '_self'),
        label: text,
    };
};
