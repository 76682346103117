// Copyright (C) 2023-2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';

import React from '@modules/react';
import { Organization } from '@root/cvat-core-wrapper';
import { PluginEntryPoint, ComponentBuilder } from '@root/components/plugins-entrypoint';
import { CombinedState } from '@root/reducers';

import consts from './consts';
import { SupportIcon, UpgradeIcon } from './icons';
import { MenuButtonHOC, MenuButtonProps } from './menu-button/menu-button';

const PLUGIN_NAME = 'Limit manager';

const UpgradeAccountComponent: ComponentBuilder = ({ core, actionCreators, store }) => {
    const EnterpriseQualityTabComponent : {
        Component: null | CallableFunction,
        isRegistered: Boolean,
    } = {
        Component: null,
        isRegistered: false,
    };

    const EnterpriseQualityAllocationTableComponent: {
        Component: null | CallableFunction,
        isRegistered: Boolean,
    } = {
        Component: null,
        isRegistered: false,
    };

    let _hasLimits: null | Promise<boolean> = null;
    const hasLimits = () => _hasLimits;
    const globalStateDidUpdate = (state: CombinedState) => {
        if (
            _hasLimits === null &&
            state.auth.user !== null &&
            state.organizations.current !== undefined
        ) {
            // login

            // organization initialized sometime after login
            // but it is necessary to get correct limits
            // so, we are waiting while organization is ready (!== undefined)
            const org = state.organizations.current;
            const { user } = state.auth;
            _hasLimits = new Promise((resolve) => {
                core.server.request('/api/limits', {
                    method: 'GET',
                    params: {
                        ...(org ? { org_id: org.id } : { user_id: user.id }),
                    },
                }).then((response) => {
                    const isPremium = response.data?.count !== 0;
                    const withEnterpriseComponents = user.isSuperuser || isPremium;

                    if (!withEnterpriseComponents) {
                        if (EnterpriseQualityTabComponent.isRegistered) {
                            store.dispatch(actionCreators.revokeUIComponent(
                                'qualityControlPage.overviewTab',
                                EnterpriseQualityTabComponent.Component,
                            ));
                            EnterpriseQualityTabComponent.isRegistered = false;
                        }

                        if (EnterpriseQualityAllocationTableComponent.isRegistered) {
                            store.dispatch(actionCreators.revokeUIComponent(
                                'qualityControlPage.allocationTable',
                                EnterpriseQualityAllocationTableComponent.Component,
                            ));
                            EnterpriseQualityAllocationTableComponent.isRegistered = false;
                        }
                    } else {
                        if (
                            EnterpriseQualityTabComponent.Component &&
                            !EnterpriseQualityTabComponent.isRegistered
                        ) {
                            store.dispatch(actionCreators.updateUIComponent(
                                'qualityControlPage.overviewTab',
                                EnterpriseQualityTabComponent.Component,
                            ));
                            EnterpriseQualityTabComponent.isRegistered = true;
                        }

                        if (
                            EnterpriseQualityAllocationTableComponent.Component &&
                            !EnterpriseQualityAllocationTableComponent.isRegistered
                        ) {
                            store.dispatch(actionCreators.updateUIComponent(
                                'qualityControlPage.allocationTable',
                                EnterpriseQualityAllocationTableComponent.Component,
                            ));
                            EnterpriseQualityAllocationTableComponent.isRegistered = true;
                        }
                    }

                    resolve(isPremium);
                });
            });
        }

        if (_hasLimits !== null && state.auth.user === null) {
            // logout
            _hasLimits = null;
        }
    };

    const upgradeButtonProps = (isPremium: boolean, currentOrganization: Organization | null): MenuButtonProps => {
        let upgradeText = isPremium ? 'Manage Solo plan' : 'Upgrade to Solo';
        let upgradeLink = `${consts.CVAT_BILLING_URL}/?type=personal`;

        if (currentOrganization) {
            upgradeText = isPremium ? 'Manage Team plan' : 'Upgrade to Team';
            upgradeLink = `${consts.CVAT_BILLING_URL}/?type=organization&orgId=${currentOrganization.id}`;
        }

        return {
            link: upgradeLink,
            text: upgradeText,
            icon: <UpgradeIcon />,
            className: 'cvat-menu-item-highlighted cvat-menu-item-upgrade',
            key: 'upgrade',
        };
    };

    const supportButtonProps = (isPremium: boolean): MenuButtonProps => {
        let supportLink = consts.CVAT_GITHUB_ISSUES_URL;

        if (isPremium) {
            supportLink = consts.CVAT_SUPPORT_TICKET_URL;
        }

        return {
            link: supportLink,
            text: 'Support',
            icon: <SupportIcon />,
            className: 'cvat-menu-item-support',
            key: 'support',
        };
    };

    const upgradeButton = MenuButtonHOC(hasLimits, upgradeButtonProps);
    const supportButton = MenuButtonHOC(hasLimits, supportButtonProps);

    const interval = setInterval(() => {
        // the way we are implementing it is quite ugly
        // because the current plugins architecture does not suppose cases
        // when plugins depends on other plugin
        // probably we need to somehow improve the implementation in the future
        const dependsOn = store.getState().plugins.current['Quality control'];
        if (typeof dependsOn === 'undefined') {
            return;
        }

        [EnterpriseQualityTabComponent.Component] = store.getState().plugins
            .overridableComponents.qualityControlPage.overviewTab.slice(-1);
        EnterpriseQualityTabComponent.isRegistered = true;
        [EnterpriseQualityAllocationTableComponent.Component] = store.getState().plugins
            .overridableComponents.qualityControlPage.allocationTable.slice(-1);
        EnterpriseQualityAllocationTableComponent.isRegistered = true;

        clearInterval(interval);
    }, 100);

    store.dispatch(actionCreators.addUIComponent('header.userMenu.items', upgradeButton, { weight: 21 }));
    store.dispatch(actionCreators.addUIComponent('header.userMenu.items', supportButton, { weight: 22 }));

    return {
        name: PLUGIN_NAME,
        globalStateDidUpdate,
        destructor: () => {
            clearInterval(interval);

            store.dispatch(actionCreators.removeUIComponent('header.userMenu.items', upgradeButton));
            store.dispatch(actionCreators.removeUIComponent('header.userMenu.items', supportButton));
        },
    };
};

function register() {
    if (Object.prototype.hasOwnProperty.call(window, 'cvatUI')) {
        (window as any as { cvatUI: { registerComponent: PluginEntryPoint } })
            .cvatUI.registerComponent(UpgradeAccountComponent);
    }
}

window.addEventListener('plugins.ready', register, { once: true });
